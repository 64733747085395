import { render, staticRenderFns } from "./cullingPattern.vue?vue&type=template&id=e20eb3fe&scoped=true&"
import script from "./cullingPattern.vue?vue&type=script&lang=js&"
export * from "./cullingPattern.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e20eb3fe",
  null
  
)

export default component.exports